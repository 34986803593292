import styled from 'styled-components'

const ComponentStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.brown};

  .betterworld-image-container {
    position: relative;
    min-height: 221px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
      min-height: 50vh;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      min-height: 221px;
    }

    .betterworld-image-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;

      &:hover {
        .play-icon {
          path:first-child {
            fill: ${({ theme }) => theme.colors.white};
          }

          path:nth-child(2) {
            fill: ${({ theme }) => theme.colors.red};
          }
        }
      }

      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        path {
          transition: 0.5s ease all;
        }
      }

      .betterworld-image {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .video-wrapper {
          width: 100%;
          height: 100%;

          .video {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .betterworld-content {
    min-height: 600px;
    flex-direction: column;
    padding: 120px 80px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
      min-height: 0px;
      padding: 60px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      min-height: 0px;
      padding: 20px;
    }

    .betterworld-title {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: ${({ theme }) => theme.colors.white};
      display: flex;
      flex-direction: column;
      margin-bottom: 45px;

      span {
        color: ${({ theme }) => theme.colors.yellow};
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 32px;
        line-height: 100%;
        letter-spacing: -0.04em;
      }
    }

    .betterworld-text1 {
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.white};
      max-width: 367px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        max-width: 100%;
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.03em;
        margin-top: 30px;
      }
    }

    .betterworld-text2 {
      font-family: 'Cabin';
      font-style: normal;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: -0.02em;
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 55px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.03em;
        margin-bottom: 30px;
      }
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      button {
        width: 100%;

        label {
          justify-content: center;
        }
      }
    }
  }
`

export default ComponentStyled
