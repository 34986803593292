import styled from 'styled-components'

export const ComponentStyled = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.secondaries.opacity100};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
    .carousel .slider-wrapper.axis-horizontal .slider .slide {
      overflow: hidden;
      padding: 30px 0px 20px 0;
    }
  }

  .howitworks-content {
    position: relative;
    overflow: hidden;
    padding: 96px 0 0 0;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
      padding: 30px 0px 20px 0;
    }

    .howitworks-title {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 100%;
      letter-spacing: -0.04em;
      color: ${({ theme }) => theme.colors.black};
      margin-bottom: 150px;
      display: flex;
      align-items: center;
      text-align: center;
      flex-direction: column;
      position: relative;
      z-index: 1;

      span {
        color: ${({ theme }) => theme.colors.red};
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        font-size: 28px;
        margin-bottom: 0;
      }
    }

    .howitworks-background {
      position: absolute;
      bottom: 0;
      height: 508px;
      width: 100%;
      background-image: url('/images/terrain.svg');
      background-position: top;
      background-repeat: repeat-x;
      left: 0;

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 90%;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.black};
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        height: 381px;
      }

      div {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }

    .howitworks-step {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 630px;
      width: 340px;
      padding-top: 440px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        width: 100%;
        height: 570px;
        padding-top: 240px;
        justify-content: flex-end;
        padding-bottom: 40px;
      }

      .howitworks-step-number {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.11em;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.secondaries.opacity100};
        margin-bottom: 50px;
        z-index: 1;

        @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
          margin-bottom: 15px;
        }
      }

      .howitworks-step-title {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.02em;
        color: ${({ theme }) => theme.colors.secondaries.opacity5};
        margin-bottom: 5px;
        z-index: 1;
      }

      .howitworks-step-description {
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        letter-spacing: -0.02em;
        color: ${({ theme }) => theme.colors.secondaries.opacity100};
        max-width: 260px;
        z-index: 1;

        @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
          font-family: 'Playfair Display';
          font-size: 24px;
          height: 140px;
          width: 80%;
          max-width: 400px;
        }
      }

      .howitworks-step-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        bottom: 0;
        z-index: 0;
      }

      .howitworks-step-image1 {
        .step-icon {
          position: absolute;
          top: 207px;
        }

        @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
          .step-icon {
            top: 92px;
          }
        }
      }

      .howitworks-step-image2 {
        .step-icon {
          position: absolute;
          top: 207px;
        }

        @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
          .step-icon {
            top: 92px;
          }
        }
      }

      .howitworks-step-image3 {
        width: 1920px;
        height: 1080px;
        left: 50%;
        transform: translateX(-50%);
        top: calc(-100% + 105px);

        @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
          width: 900px;
          top: calc(-100% + 135px);
        }
      }
    }

    .howitworks-plant-wish {
      display: flex;
      justify-content: center;
      margin-bottom: 120px;
      margin-top: 50px;
    }

    .howitworks-indicator {
      border: 1px solid ${({ theme }) => theme.colors.secondaries.opacity100};
      border-radius: 5px;
      width: 8px;
      height: 8px;
      display: inline-block;
      margin: 4px;
      transition: width 0.25s ease-in-out;
    }

    .howitworks-indicator-selected {
      width: 40px;
      height: 8px;
      background: ${({ theme }) => theme.colors.secondaries.opacity100};
      border-radius: 5px;
      display: inline-block;
      margin: 4px;
      transition: width 0.25s ease-in-out;
    }

    .howitworks-arrow {
      padding: 10px 14px;
      position: absolute;
      z-index: 1;
      bottom: 40px;
      transform: translate(-50%, 0%);
    }

    .howitworks-arrow-left {
      left: calc(50% - 20px);
      > svg {
        fill: ${({ theme }) => theme.colors.secondaries.opacity100};
      }
    }

    .howitworks-arrow-right {
      left: calc(50% + 20px);
      > svg {
        fill: ${({ theme }) => theme.colors.secondaries.opacity100};
      }
    }
  }
`

export const Title = styled.div``

export const ButtonScroll = styled.button`
  width: 50px;
  height: 50px;
  background-color: $red;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  bottom: 60px;
  z-index: 4;
  border: none;
`
