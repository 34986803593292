const videoByLocale = [
  {
    key: 'en',
    id: '2ZVpawklP5k',
  },
  {
    key: 'es',
    id: 'EmMaOSShZyY',
  },
  {
    key: 'it',
    id: '2P_Lctn4mDE',
  },
  {
    key: 'de',
    id: 'PBW8mrCdfOc',
  },
  {
    key: 'pt',
    id: 'N-pKrfTiRk8',
  },
  {
    key: 'si',
    id: 'jn0AGTFvVa8',
  },
  {
    key: 'pl',
    id: '52IxxHLbgks',
  },
  {
    key: 'cs-CZ',
    id: 'gUClNo6xbr0',
  },
  {
    key: 'fr',
    id: 'OJrTOZ8Pd6U',
  },
  {
    key: 'hu',
    id: 'QEeG1W2f6GI',
  },
  {
    key: 'en-US',
    id: 'Shu5TLiir6c',
  },
  {
    key: 'si',
    id: '6nkLrJwIJpc',
  },
  {
    key: 'en-CA',
    id: 'q0LRw42sBEU',
  },
  {
    key: 'hr',
    id: 'Hb8t2nqdv3s',
  },
  {
    key: 'sk',
    id: 'wAuv2aShwNU',
  },
  {
    key: 'cs-CZ',
    id: 'ULPMB80A-nA',
  },
]

export const getVideoByLocale = (locale: string | undefined) => {
  const videoList = videoByLocale.filter(item => item.key === locale)
  if (videoList.length > 0) {
    return videoList[0].id
  }

  return videoByLocale[0].id
}
