import styled from 'styled-components'

const ComponentStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-bottom: 40px;
  }

  .sticky-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 5px;
    background-color: ${({ theme }) => theme.colors.black};
    z-index: 4;
  }
`

export default ComponentStyled
