import { useEffect, useState } from 'react'

import { useTranslation, Trans } from 'react-i18next'
import Image from 'next/image'
import { Grid, Theme, useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'
import YouTube from 'react-youtube'

import routes from '@/config/routes'
import PlayIcon from 'public/images/play.svg'
import LinkRegular from '@/components/atoms/LinkRegular'
import Button from '@/components/atoms/Button'

import { getVideoByLocale } from './utils'
import ComponentStyled from './styled'

const BetterWorld = (): JSX.Element => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 0,
      loop: 1,
    },
  }

  useEffect(() => {
    setIsPlaying(false)
  }, [locale])

  return (
    <ComponentStyled>
      <Grid container justifyContent='space-between'>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12} className='betterworld-content'>
          <p className='betterworld-title'>
            <Trans i18nKey='a little piece <1>of a better world.</1>'>
              a little piece <span>of a better world.</span>
            </Trans>
          </p>
          {locale !== 'en-GB' && (
            <>
              <p className='betterworld-text1'>
                {t('100 years ago, we dreamed about a world full of color, full of life.')}
              </p>
              <p className='betterworld-text2'>
                {t(
                  'Today, we take on the commitment to protect this dream, and make it possible for the next 100 years.'
                )}
              </p>
            </>
          )}
          <LinkRegular href={routes.wishes}>
            <Button label={t('Visit wish fields')} size='medium' fitToContainer={isMobile} />
          </LinkRegular>
        </Grid>
        <Grid item flexGrow={1} xl={8} lg={8} md={12} sm={12} xs={12} className='betterworld-image-container'>
          <div className='betterworld-image-wrapper'>
            <div className='betterworld-image' onClick={() => setIsPlaying(true)}>
              {!isPlaying ? (
                <>
                  <PlayIcon className='play-icon' />
                  <Image src='/images/video_bg.png' layout='fill' objectFit='cover' alt={t('Video')} />
                </>
              ) : (
                <YouTube
                  className='video-wrapper'
                  iframeClassName='video'
                  videoId={getVideoByLocale(locale)}
                  opts={videoOptions}
                  onEnd={() => {
                    setIsPlaying(false)
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </ComponentStyled>
  )
}

export default BetterWorld
