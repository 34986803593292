import styled from 'styled-components'

const ComponentStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.brown};
  display: flex;
  flex-direction: row;

  .croptrust-image {
    position: relative;
    height: 100%;
    min-height: 708px;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      min-height: 347px;
    }
  }

  .croptrust-content {
    display: flex;
    flex-direction: column;
    padding: 40px 120px;
    align-items: flex-start;
    min-height: 650;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
      padding: 60px 120px 20px 60px;
      min-height: 0px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      min-height: 347px;
      padding: 60px 120px 20px 60px;
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
      min-height: 347px;
      padding: 20px 20px 60px 20px;
    }

    .croptrust-title {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: ${({ theme }) => theme.colors.white};
      display: flex;
      margin-top: 32px;
      flex-direction: column;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 32px;
        line-height: 100%;
        letter-spacing: -0.04em;
      }

      span {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }

    .croptrust-text1 {
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: ${({ theme }) => theme.colors.white};
      margin-top: 30px;
      max-width: 500px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        max-width: 100%;
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.03em;
      }
    }

    .croptrust-text2 {
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      color: ${({ theme }) => theme.colors.white};
      margin-top: 30px;
      max-width: 500px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        max-width: 100%;
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.03em;
      }
    }

    .croptrust-link-container {
      display: flex;
      align-items: center;
      margin-top: 20px;

      .croptrust-link {
        margin: 0 0 0 5px;
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        width: 100%;
        flex-direction: column;

        .about-biodiversity,
        .discover-more {
          width: 100%;
        }

        .croptrust-link {
          width: 100%;
          margin: 10px 0 0 0;
        }
      }
    }

    .croptrust-link {
      display: flex;
      text-align: center;
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      display: flex;
      letter-spacing: -0.02em;
      color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.brown};
      padding: 11px 12px;
      margin-top: 30px;
      transition: 0.5s ease all;
      border-radius: 4px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        letter-spacing: -0.02em;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        display: block;
      }

      &:hover {
        color: ${({ theme }) => theme.colors.red};
        border-color: ${({ theme }) => theme.colors.red};
      }
    }
  }
`

export default ComponentStyled
