import { useEffect, useState } from 'react'

import { Container, Grid, Theme, useMediaQuery } from '@mui/material'
import Image from 'next/image'
import { useTranslation, Trans } from 'react-i18next'

import Button from '@/components/atoms/Button'
import Seed from '@/components/atoms/Seed'
import WishItem from '@/components/molecules/WishItem'
import WishesStatus from '@/components/molecules/WishesStatus'
import PlantYourWishButton from '@/components/molecules/PlantYourWishButton'
import { useGetWishes } from '@/services/wish/hooks'
import WishInterface from '@/model/interfaces/wish'
import LeafsIcon from 'public/images/leafs.svg'
import { useGetPlantTypes } from '@/services/plant-type/hooks'

import ComponentStyled from './styled'

const LAST_WISH_PARAMS = '?sort[0]=id%3Adesc&pagination[limit]=10&populate[0]=user_info&populate[1]=plant_type.icon'

type Props = {
  isCompleted?: boolean
}

let interval: ReturnType<typeof setInterval>

const Hero = ({ isCompleted = false }: Props): JSX.Element => {
  const [activeWishIndex, setActiveWishIndex] = useState(-1)
  const [mouseIn, setMouseIn] = useState<boolean>(false)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const { data: plants } = useGetPlantTypes()
  const { data: lastWishes } = useGetWishes(LAST_WISH_PARAMS, {
    enabled: activeWishIndex > -1 && !!plants,
  })

  useEffect(() => {
    if (activeWishIndex === -1) {
      setActiveWishIndex(0)
    }

    if (lastWishes?.length > 0) {
      interval = setInterval(() => {
        setActiveWishIndex(state => (state + 1) % lastWishes.length)
      }, 5000)

      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastWishes])

  useEffect(() => {
    if (interval) {
      if (mouseIn) {
        clearInterval(interval)
      } else {
        interval = setInterval(() => {
          setActiveWishIndex(state => (state + 1) % lastWishes.length)
        }, 5000)
      }
    }
  }, [mouseIn])

  return (
    <ComponentStyled>
      {!isMobile ? (
        <video autoPlay muted loop playsInline controls={false} className='video'>
          <source src='/videos/hero.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className='hero-image-mobile'>
          <Image src='/images/image-mobile.png' priority layout='fill' objectFit='cover' alt='image-mobile' />
        </div>
      )}
      <div className='hero-content'>
        <Container maxWidth='xl'>
          <Grid container spacing={4} justifyContent={isMobile ? 'center' : 'space-between'} alignItems='center'>
            <Grid item>
              <div className='left-content'>
                <HeroTitle isMobile={isMobile} isCompleted={isCompleted} />
              </div>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sm='auto'
              onMouseEnter={() => setMouseIn(true)}
              onMouseLeave={() => setMouseIn(false)}
            >
              {lastWishes.length > 0 && (
                <div className='seed-wish-box'>
                  {lastWishes.map((wish, index) => {
                    const plantTitle =
                      plants?.find(v => v.attributes.slug == wish.attributes?.plant_type.data.attributes.slug)
                        ?.attributes.title || ''

                    return (
                      <SeedWish
                        key={wish.id}
                        wish={wish}
                        plantTitle={plantTitle}
                        isActive={index === activeWishIndex}
                      />
                    )
                  })}
                </div>
              )}
              <WishesStatus />
            </Grid>
          </Grid>
        </Container>
      </div>
    </ComponentStyled>
  )
}

type HeroProps = {
  isMobile?: boolean
  isCompleted?: boolean
}

const HeroTitle = ({ isMobile = false, isCompleted = false }: HeroProps) => {
  const { t } = useTranslation()

  return (
    <>
      <h1 className='hero-title'>
        {isCompleted ? (
          <Trans i18nKey='thanks for <1>your help</1>'>
            thanks for <span>your help</span>
          </Trans>
        ) : (
          <Trans i18nKey='plant your wish <1>for the future.</1>'>
            plant your wish <span>for the future.</span>
          </Trans>
        )}
      </h1>
      <p className='hero-copy'>
        {isCompleted ? (
          <strong>
            {t(
              'Thanks to the wishes planted, we can preserve gluten free crops for the next 100 years along with our partners from the Crop Trust.'
            )}
          </strong>
        ) : (
          <>
            <strong>{t('Help us to conserve gluten free crops for the next 100 years.')}</strong>{' '}
            {t('For each wish, we’ll preserve a crop for +1 year along with our partners from the Crop Trust.')}
          </>
        )}
      </p>
      {isCompleted ? (
        <Button label={t('Visit wish field')} fitToContainer={isMobile} icon={<LeafsIcon />} size='large' />
      ) : (
        <PlantYourWishButton fitToContainer={isMobile} />
      )}
    </>
  )
}

type SeedWishProps = {
  wish: WishInterface
  isActive: boolean
  plantTitle: string
}

const SeedWish = ({ wish, isActive, plantTitle }: SeedWishProps) => {
  const { t } = useTranslation()

  const wishIcon = wish?.attributes?.plant_type?.data?.attributes?.icon?.data?.attributes?.url
  const wishComment = wish?.attributes?.text
  const wishLocation = wish?.attributes?.user_info?.location
  const wishUserName = wish?.attributes?.user_info?.name
  const wishAvatar = wish?.attributes?.user_info?.image_url

  if (!isActive) return null

  return (
    <div className={`seed-wish-wrapper ${isActive ? 'active' : ''}`}>
      {plantTitle && (
        <div className='seed-wrapper'>
          {wishIcon && (
            <div className='seed-img'>
              <Seed url={wishIcon} title={plantTitle} />
            </div>
          )}
          <p className='seed-copy'>
            <span>{t('+1 year')}</span> {plantTitle}
          </p>
        </div>
      )}
      {wishComment && (
        <div className='wish-wrapper'>
          <WishItem
            wishId={wish.id}
            username={wishUserName || ''}
            avatar={wishAvatar}
            comment={wishComment}
            location={wishLocation || ''}
          />
        </div>
      )}
    </div>
  )
}

export default Hero
