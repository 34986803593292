import { useTranslation } from 'react-i18next'

import Button from '@/components/atoms/Button'
import LinkRegular from '@/components/atoms/LinkRegular'
import LeafsIcon from 'public/images/leafs.svg'
import routes from '@/config/routes'

type Props = {
  size?: 'small' | 'regular' | 'medium' | 'large'
  fitToContainer?: boolean
}

const PlantYourWishButton = ({ size = 'regular', fitToContainer = false }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <LinkRegular href={routes.wish}>
      <Button label={t('Plant your wish')} icon={<LeafsIcon />} size={size} fitToContainer={fitToContainer} />
    </LinkRegular>
  )
}

export default PlantYourWishButton
