import styled from 'styled-components'

const ComponentStyled = styled.div`
  position: relative;
  padding: 220px 0;
  min-height: 900px;
  display: flex;
  align-items: center;

  .seed-wish-box {
    position: relative;
    width: 380px;
    max-width: 100%;
    min-height: 290px;
  }

  &&::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.black35};
  }

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 180px 0 16px 0;

    .seed-wish-box {
      width: 100%;
    }
  }

  .video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    object-fit: cover;
    pointer-events: none;

    &::-webkit-media-controls {
      display: none;
    }

    &::-webkit-media-controls-panel {
      display: none;
    }
  }

  .hero-image-mobile {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    object-fit: cover;
    pointer-events: none;
  }

  .hero-content {
    position: relative;
    z-index: 4;
    flex: 1;

    .left-content {
      margin-left: 100px;
      max-width: 430px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        margin-left: 0px;
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        max-width: 275px;
      }
    }

    .hero-title {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 48px;
      letter-spacing: 0.01em;
      color: ${({ theme }) => theme.colors.secondaries.opacity100};
      margin-bottom: 20px;
      max-width: 345px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        text-align: center;
      }

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        font-size: 32px;
        line-height: 32px;
      }

      span {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }

    .hero-copy {
      font-family: 'Cabin';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: ${({ theme }) => theme.colors.secondaries.opacity10};
      margin-bottom: 20px;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
        text-align: center;
        font-size: 18px;
        line-height: 29px;
      }
    }

    .seed-wish-wrapper {
      width: 100%;
      position: absolute;

      @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
        width: 100%;
      }

      &.active {
        animation: slideDown 2s;
      }

      .wish-wrapper {
        margin-bottom: 50px;
      }

      .seed-wrapper {
        text-align: center;
        margin-bottom: 10px;

        .seed-copy {
          font-family: 'Playfair Display';
          font-weight: 900;
          font-size: 32px;
          line-height: 32px;
          letter-spacing: -0.04em;
          color: ${({ theme }) => theme.colors.secondaries.opacity100};

          @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
            font-size: 24px;
            line-height: 24px;
          }

          span {
            color: ${({ theme }) => theme.colors.yellow};
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
      .left-content {
        margin-bottom: 94px;
      }
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(-50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

export default ComponentStyled
