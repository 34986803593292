import { Theme, useMediaQuery } from '@mui/material'

import PlantYourWishButton from '@/components/molecules/PlantYourWishButton'
import Footer from '@/components/organisms/Footer'
import Header from '@/components/organisms/Header'
import Hero from '@/components/organisms/Hero'
import HowItWorks from '@/components/organisms/HowItWorks'
import CropTrust from '@/components/organisms/CropTrust'
import BetterWorld from '@/components/organisms/BetterWorld'
import { useGetCurrentMilestone } from '@/services/milestone/hooks'
import { isObjectiveCompleted } from '@/utils/index'

import ComponentStyled from './styled'

const Home = () => {
  const { data: currentMilestone } = useGetCurrentMilestone()
  const isCompleted = currentMilestone ? isObjectiveCompleted(currentMilestone) : false
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <ComponentStyled>
      <Header showBlackLogo={false} />
      <div className='global-page-wrapper'>
        <Hero isCompleted={isCompleted} />
        <HowItWorks isCompleted={isCompleted} />
        <CropTrust />
        <BetterWorld />
      </div>
      <Footer />
      {isMobile && !isCompleted && (
        <div className='sticky-btn'>
          <PlantYourWishButton fitToContainer />
        </div>
      )}
    </ComponentStyled>
  )
}
export default Home
