import { useTranslation } from 'react-i18next'

//import LeafsIcon from 'public/images/leafs.svg'
import { useGetCurrentMilestone /* , useGetMilestones */ } from '@/services/milestone/hooks'

import ComponentStyled from './styled'

type Props = {
  color?: 'yellow' | 'black'
}

//const MILESTONES_PARAMS = '?fields[0]=name&fields[1]=distance&fields[2]=reached&sort[0]=distance:asc'
//const NEXT_MILESTONES_PARAMS = '?filters[reached][$eq]=false&sort[0]=distance%3Aasc&pagination[limit]=1'

const WishesStatus = ({ color = 'yellow' }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { data: currentMilestone } = useGetCurrentMilestone()
  //const { data: milestones } = useGetMilestones(MILESTONES_PARAMS)
  //const { data: nextMilestones } = useGetMilestones(NEXT_MILESTONES_PARAMS)

  const currentWishes = currentMilestone?.current || 0
  const totalObjective = currentMilestone?.total || 0
  const progressPercent = (currentWishes * 100) / totalObjective
  //const nextMilestone = nextMilestones?.[0]?.attributes.distance
  const isObjectiveComplete = currentWishes >= totalObjective && totalObjective > 0

  return (
    <ComponentStyled color={color} progressWidthPercent={progressPercent}>
      <p className='total-wishes'>
        <span>+{currentWishes}</span> {isObjectiveComplete ? t('wishes planted') : t('wishes')}
      </p>
      {/*<div className='status-bar'>
        <div className='progress' />
        {milestones.map(milestone => {
          const distance = milestone?.attributes?.distance || 0

          if (distance > totalObjective) return

          return (
            <div
              key={milestone.id}
              className={`icon-wrapper ${milestone?.attributes.reached && 'active'}`}
              style={{ left: `calc(${(distance * 100) / totalObjective}% - 10px)` }}
            >
              <LeafsIcon className='icon' />
            </div>
          )
        })}
        <div className='icon-wrapper last'>
          <LeafsIcon className='icon' />
        </div>
      </div>
      <div className={`bottom-status ${isObjectiveComplete ? 'objetive-completed' : ''}`}>
        {!isObjectiveComplete && (
          <div>
            <p className='status-title'>{t('Next Milestone')}</p>
            <p className='status-copy'>{nextMilestone}</p>
          </div>
        )}
        <div>
          <p className='status-title'>{t('Objective')}</p>
          <p className='status-copy'>{totalObjective}</p>
        </div>
        </div>*/}
    </ComponentStyled>
  )
}

export default WishesStatus
