import { NextSeo } from 'next-seo'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Home from '@/components/pages/Home'

import type { NextPage } from 'next'

const HomePage: NextPage = () => {
  return (
    <>
      <NextSeo />
      <Home />
    </>
  )
}

type StaticProps = {
  locale: string
}

export async function getStaticProps({ locale }: StaticProps) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default HomePage
