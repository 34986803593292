import { MouseEventHandler, useEffect, useRef } from 'react'

import { useTranslation, Trans } from 'react-i18next'
import { Container, Grid, Theme, useMediaQuery } from '@mui/material'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import lottie from 'lottie-web'

import Button from '@/components/atoms/Button'
import PlantYourWishButton from '@/components/molecules/PlantYourWishButton'
import LeftArrow from 'public/images/left-arrow-carrusel.svg'
import Step1Icon from 'public/images/step1.svg'
import LeafsIcon from 'public/images/leafs.svg'
import Step2Icon from 'public/images/step2.svg'
import RightArrow from 'public/images/right-arrow-carrusell.svg'
import animationData from '@/assets/animations/step-3.json'
import useOnScreen from '@/hooks/useOnScreen'
import DownIcon from 'public/images/arrow-down.svg'

import { ComponentStyled, ButtonScroll } from './styled'

type Props = {
  isCompleted?: boolean
}

const HowItWorks = ({ isCompleted = false }: Props): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))
  return isMobile ? <ContentMobile /> : <ContentDesktop isCompleted={isCompleted} />
}

const ContentMobile = () => {
  const container = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(container)
  const renderIndicator = (onClick: MouseEventHandler<HTMLLIElement>, selected: boolean) => (
    <li onClick={onClick} className={selected ? 'howitworks-indicator-selected' : 'howitworks-indicator'}></li>
  )

  const renderArrowPrev = (onClick: MouseEventHandler<HTMLLIElement>, enabled: boolean) =>
    enabled ? (
      <span className='howitworks-arrow howitworks-arrow-left' onClick={onClick}>
        <LeftArrow />
      </span>
    ) : null

  const renderArrowNext = (onClick: MouseEventHandler<HTMLLIElement>, enabled: boolean) =>
    enabled ? (
      <span className='howitworks-arrow howitworks-arrow-right' onClick={onClick}>
        <RightArrow />
      </span>
    ) : null

  const handleClick = () => {
    const nextContainer = document.getElementById('next-container')
    if (nextContainer) {
      nextContainer.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' })
    }
  }

  return (
    <>
      <ComponentStyled ref={container}>
        <div className='howitworks-content'>
          <Title />
          <BackgroundLand />
          <Carousel
            swipeable
            showThumbs={false}
            showStatus={false}
            renderIndicator={renderIndicator}
            renderArrowPrev={renderArrowPrev}
            renderArrowNext={renderArrowNext}
          >
            <Step1 />
            <Step2 />
            <Step3 />
          </Carousel>
        </div>
        {isVisible && (
          <ButtonScroll onClick={() => handleClick()}>
            <DownIcon />
          </ButtonScroll>
        )}
      </ComponentStyled>
    </>
  )
}

type ContentDesktopProps = {
  isCompleted?: boolean
}

const ContentDesktop = ({ isCompleted = false }: ContentDesktopProps) => {
  const { t } = useTranslation()

  return (
    <ComponentStyled>
      <div className='howitworks-content'>
        <Title />
        <Container maxWidth='lg'>
          <BackgroundLand />
          <Grid container justifyContent='space-around' alignItems='flex-end'>
            <Step1 />
            <Step2 />
            <Step3 />
          </Grid>
        </Container>
        <div className='howitworks-plant-wish'>
          {isCompleted ? (
            <Button label={t('Visit wish field')} icon={<LeafsIcon />} size='large' />
          ) : (
            <PlantYourWishButton size='large' />
          )}
        </div>
      </div>
    </ComponentStyled>
  )
}

const Title = () => (
  <div className='howitworks-title'>
    <Trans i18nKey='how does it <1>work?</1>'>
      how does it <span>work?</span>
    </Trans>
  </div>
)

const BackgroundLand = () => {
  return <div className='howitworks-background' />
}

const Step1 = () => {
  const { t } = useTranslation()

  return (
    <Grid item className='howitworks-step'>
      <div className='howitworks-step-image howitworks-step-image1'>
        <Step1Icon className='step-icon' />
      </div>
      <span className='howitworks-step-number'>{t('STEP 1')}</span>
      <span className='howitworks-step-title'>{t('Choose a seed')}</span>
      <span className='howitworks-step-description'>
        {t('Choose the crop you would like to preserve for the future. Together we can make it!')}
      </span>
    </Grid>
  )
}

const Step2 = () => {
  const { t } = useTranslation()

  return (
    <Grid item className='howitworks-step'>
      <div className='howitworks-step-image howitworks-step-image2'>
        <Step2Icon className='step-icon' />
      </div>
      <span className='howitworks-step-number'>{t('STEP 2')}</span>
      <span className='howitworks-step-title'>{t('Write your wish')}</span>
      <span className='howitworks-step-description'>{t('Leave your wish for a better future.')}</span>
    </Grid>
  )
}

const Step3 = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const container = document.querySelector('#step3')

    if (container) {
      lottie.loadAnimation({
        container,
        animationData,
        renderer: 'svg',
        loop: true,
        autoplay: true,
      })
    }
  }, [])

  return (
    <Grid item className='howitworks-step'>
      <div className='howitworks-step-image howitworks-step-image3' id='step3' />

      <span className='howitworks-step-number'>{t('STEP 3')}</span>
      <span className='howitworks-step-title'>{t('We`ll do the rest!')}</span>
      <span className='howitworks-step-description'>
        {t('Along with the Crop Trust we are going to preserve the seed for +1 year.')}
      </span>
    </Grid>
  )
}

export default HowItWorks
