import styled from 'styled-components'

type Props = {
  color: 'yellow' | 'black'
  progressWidthPercent: number
}

const ComponentStyled = styled.div<Props>`
  .total-wishes {
    font-family: 'Playfair Display';
    font-weight: 900;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: ${({ theme, color }) => (color === 'yellow' ? theme.colors.secondaries.opacity100 : theme.colors.black)};
    margin-bottom: 40px;
    text-align: center;

    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
      font-size: 24px;
      line-height: 24px;
    }

    span {
      color: ${({ theme, color }) => (color === 'yellow' ? theme.colors.yellow : theme.colors.red)};
    }
  }

  .status-bar {
    height: 20px;
    border-radius: 20px;
    border: 1px solid ${({ theme, color }) => (color === 'yellow' ? theme.colors.yellow : theme.colors.black)};
    margin-bottom: 15px;
    position: relative;

    .progress {
      content: '';
      position: absolute;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      border-top-right-radius: ${({ progressWidthPercent }) => `${progressWidthPercent > 100 ? 20 : 0}px`};
      border-bottom-right-radius: ${({ progressWidthPercent }) => `${progressWidthPercent > 100 ? 20 : 0}px`};
      left: 0;
      width: ${({ progressWidthPercent }) => `${progressWidthPercent > 100 ? 100 : progressWidthPercent}%`};
      height: 100%;
      background-color: ${({ theme, color }) => (color === 'yellow' ? theme.colors.yellow : theme.colors.black)};
    }

    .icon-wrapper {
      position: absolute;
      top: -20px;

      &.last {
        left: calc(100% - 10px);
      }

      &.active::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 20px;
        width: 1px;
        height: 18px;
        background-color: ${({ theme, color }) => (color === 'yellow' ? theme.colors.black : theme.colors.white)};
      }

      .icon {
        path {
          fill: ${({ theme, color }) =>
            color === 'yellow' ? theme.colors.secondaries.opacity100 : theme.colors.blackOpacity};
        }
      }

      &.active path {
        fill: ${({ theme, color }) => (color === 'yellow' ? theme.colors.yellow : theme.colors.black)};
      }
    }
  }

  .bottom-status {
    display: flex;
    justify-content: space-between;

    &.objetive-completed {
      justify-content: flex-end;
    }

    .status-title {
      margin-bottom: 10px;
    }

    .status-title {
      font-family: 'Cabin';
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.11em;
      text-transform: uppercase;
      color: ${({ theme, color }) => (color === 'yellow' ? theme.colors.secondaries.opacity100 : theme.colors.black)};
    }

    .status-copy {
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 20px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: ${({ theme, color }) => (color === 'yellow' ? theme.colors.secondaries.opacity100 : theme.colors.black)};
    }
  }
`

export default ComponentStyled
