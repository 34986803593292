import { useTranslation, Trans } from 'react-i18next'
import Image from 'next/image'
import { Grid } from '@mui/material'

import routes from '@/config/routes'
import Button from '@/components/atoms/Button'
import LinkRegular from '@/components/atoms/LinkRegular'
import CropTrustLogo from 'public/images/crop-trust-logo.svg'
import { EXTERNAL_URLS } from '@/config/constants'

import ComponentStyled from './styled'

const CropTrust = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ComponentStyled id='next-container'>
      <Grid container justifyContent='space-between'>
        <Grid item flexGrow={1} xs={12} md={12} sm={12} lg={6}>
          <div className='croptrust-image'>
            <Image src='/images/crop-trust.jpg' layout='fill' objectFit='cover' alt={t('the Crop Trust')} priority />
          </div>
        </Grid>
        <Grid item xs={12} md={12} sm={12} lg={6}>
          <div className='croptrust-content'>
            <CropTrustLogo />
            <p className='croptrust-title'>
              <Trans i18nKey='our shared commitment <1>with the CropTrust.</1>'>
                our shared commitment <span>with the CropTrust.</span>
              </Trans>
            </p>
            <p className='croptrust-text1'>
              {t(
                "To celebrate our company's 100 year anniversary, we set the goal of protecting biodiversity for future generations. In order to achieve it, we found the best possible partner: the Crop Trust!"
              )}
            </p>
            <p className='croptrust-text2'>
              {t(
                'The Crop Trust is an international organization devoted to conserving and ensuring the availabilty of crop biodiversity worldwide, forever and for everyone.'
              )}
            </p>
            <p className='croptrust-text2'>
              {t(
                'Together with your help, we will make sure that, up to 500 varieties of 5 different crops - beans, lentils, pea, pearl millet and sorghum are properly guarded for, at least, the next 100 years.'
              )}
            </p>
            <p className='croptrust-link-container'>
              <div className='discover-more'>
                <LinkRegular href={routes.biodiversity}>
                  <Button fitToContainer label={t('Discover more')} size='medium' />
                </LinkRegular>
              </div>
              <div className='about-biodiversity'>
                <a href={EXTERNAL_URLS.CROP_TRUST} target='_blank' rel='noreferrer' className='discover-more'>
                  <span className='croptrust-link'>{t('Crop Trust')}</span>
                </a>
              </div>
            </p>
          </div>
        </Grid>
      </Grid>
    </ComponentStyled>
  )
}

export default CropTrust
